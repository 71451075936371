<template>
  <v-dialog v-model="isDialogOpen" max-width="600px" scrollable persistent>
    <v-card>
      <v-card-title class="white--text info">
        <v-icon color="white" class="ml-2">fal fa-user</v-icon>
        <span class="ml-4">{{ $t('addOrRemoveSingleUsers') }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="search"
          class="ma-3"
          flat
          :label="$t('searchUsers')"
          prepend-inner-icon="fa-search"
          underline
          clearable
          @click:clear="clearSearch"
        />
        <div v-if="filteredUsers.length === 0" class="mx-3 body-1">
          {{ $t('searchUsersNoResults') }}
        </div>

        <v-list flat>
          <v-list-item-group v-model="selectedUsersIgnore" multiple>
            <template v-for="user in filteredUsers">
              <v-list-item
                :key="user.id"
                :value="user"
                :class="{ selectedUser: user.isSelected }"
                @change="toggleUserSelectedState(user)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="user.name" />
                  <v-list-item-subtitle> {{ user.email }} {{ user.msisdn }} </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox :input-value="user.isSelected" color="blueDark" />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="isDialogOpen = false">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" @click="done">{{ $t('done') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SelectUsersDialog',

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
    target: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data: () => ({
    selectedUsersIgnore: [], // Ignore this, search makes this unreliable.
    search: '',
    originalTargetUsersIds: null,
  }),

  computed: {
    isDialogOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    filteredUsers() {
      if (!this.search) {
        return this.users;
      } else {
        return this.users.filter(
          (user) =>
            user.name.toLowerCase().includes(this.search.toLowerCase()) ||
            user.msisdn.toLowerCase().includes(this.search.toLowerCase()) ||
            user.email.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
    },
  },

  watch: {
    value(visible) {
      if (visible) {
        this.preselectedUsers();
      }
    },
  },

  methods: {
    stringifyUserIds(selectedUsers) {
      return JSON.stringify(selectedUsers.map((u) => u.id).sort((a, b) => a - b));
    },

    preselectedUsers() {
      // Used to populate selected users when modal opens
      let userIds = [];
      if (this.target.company === true) {
        this.users.forEach((user) => {
          userIds.push(user.id);
        });
      } else if (this.target.teams.length > 0) {
        this.target.teams.forEach((team) => {
          team.userIds.forEach((id) => {
            userIds.push(id);
          });
        });
      } else if (this.target.users.length > 0) {
        this.target.users.forEach((user) => {
          userIds.push(user.id);
        });
      }
      const uniqueIds = [...new Set(userIds)];

      // TODO: Don't mutate the user prop, save in an internal class and array
      this.users.forEach((user) => {
        if (uniqueIds.includes(user.id)) {
          user.isSelected = true;
        } else {
          user.isSelected = false;
        }
      });

      const selectedUsers = this.users.filter((u) => u.isSelected);
      // Store userids from selectedUsers for comparison when done is clicked
      this.originalTargetUsersIds = this.stringifyUserIds(selectedUsers);
    },

    toggleUserSelectedState(item) {
      item.isSelected = !item.isSelected;
    },

    clearSearch() {
      this.search = '';
    },

    done() {
      // Check if targeted users has changed
      const selectedUsers = this.users.filter((u) => u.isSelected);
      const hasChanges = this.originalTargetUsersIds !== this.stringifyUserIds(selectedUsers);

      if (!hasChanges) {
        this.$emit('done', this.target);
        this.isDialogOpen = false;
      } else {
        if (selectedUsers.length === this.users.length) {
          // All users selected, use company for convenience
          const target = {
            company: true,
            teams: [],
            users: [],
          };
          this.$emit('done', target);
          this.isDialogOpen = false;
        } else {
          // Changes done to selected users, update target object
          const target = {
            company: false,
            teams: [],
            users: selectedUsers,
          };
          this.$emit('done', target);
          this.isDialogOpen = false;
        }
      }
    },
  },
};
</script>

<style>
.selectedUser {
  background-color: #c3c7c72e;
}
</style>
