<template>
  <v-alert
    v-if="text"
    border="top"
    colored-border
    color="red"
    elevation="2"
    class="text-body-2 text--secondary"
  >
    {{ text }}
  </v-alert>
</template>

<script>
import { translate as t } from '@/util/i18n';

export default {
  name: 'RecipientReach',

  props: {
    hasFallbackSms: {
      type: Boolean,
      required: true,
    },

    usersWithDevicesCount: {
      type: Number,
      required: true,
    },

    usersWithoutDevicesCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    text() {
      if (!this.hasFallbackSms && this.usersWithDevicesCount === 0) {
        return t('reachWithoutFallbackNoDevices');
      }

      return null;
    },
  },
};
</script>
