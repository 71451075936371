<template>
  <div>
    <div class="map-container" :class="{ show: showMap, fullscreen }">
      <div class="map-panel-container">
        <v-card class="map-panel" :class="{ show: showMapPanel }">
          <v-card-text>
            <div>{{ $t('mapPanelHelperText') }}</div>
            <div class="d-flex py-4 justify-space-between">
              <v-btn :disabled="currentLocation != null" @click="openMapTools">{{
                currentAdHocLocation ? $t('editCustomLocation') : $t('drawCustomLocation')
              }}</v-btn>
              <v-btn
                :disabled="currentAdHocLocation === null"
                icon
                @click="
                  currentAdHocLocation = null;
                  map.clearDrawing();
                "
                ><v-icon class="pa-1">fa-times</v-icon></v-btn
              >
            </div>
            <v-autocomplete
              v-model="currentLocation"
              :items="locations"
              return-object
              item-text="name"
              item-value="id"
              :disabled="currentAdHocLocation != null"
              chips
            >
              <template v-if="currentLocation" #append>
                <v-btn icon @click.stop="currentLocation = null"
                  ><v-icon class="pa-1">fa-times</v-icon></v-btn
                >
              </template>
            </v-autocomplete>
            <div class="d-flex justify-end">
              <v-btn text @click="cancelEditLocation">{{ $t('cancel') }}</v-btn>
              <v-btn color="primary" @click="useCurrentLocationOrAdHocLocation">{{
                $t('done')
              }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <div class="map-draw-tools" :class="{ show: showMapTools }">
          <v-card class="mb-4">
            <v-btn text tile @click="startDrawingPolygon">
              {{ $t('polygon') }}<v-icon right>fa-fw fa-draw-polygon</v-icon>
            </v-btn>
            <v-btn text tile @click="startDrawingCircle">
              {{ $t('circle') }}<v-icon right>fa-fw far fa-circle</v-icon>
            </v-btn>
          </v-card>
          <v-card class="d-flex flex-column">
            <v-btn text tile @click="cancelEditCurrentAdHocLocation">{{ $t('cancel') }}</v-btn>
            <v-btn text tile @click="clearCurrentAdHocLocation">{{ $t('clear') }}</v-btn>
            <v-btn text tile @click="useCurrentAdHocLocation">{{ $t('done') }}</v-btn>
          </v-card>
        </div>
      </div>
      <div ref="map" class="map-wrapper" />
    </div>
    <div
      class="form-container pa-lg-4 d-lg-flex align-center justify-center"
      :class="{ show: showForm, fullscreen }"
    >
      <v-card class="form-card">
        <v-card-text>
          <v-tabs v-show="false" v-model="tab">
            <v-tab />
            <v-tab />
          </v-tabs>
          <v-tabs-items v-model="tab" style="background: transparent">
            <v-tab-item eager>
              <div class="text-lg-center">
                <div v-if="isEditor && !hasData" class="text-h4">{{ $t('createAlert') }}</div>
                <div v-else-if="isEditor && hasData" class="text-h4">{{ $t('editAlert') }}</div>
                <div v-else class="text-h4">{{ $t('newAlertPageTitle') }}</div>
                <div class="text-subtitle-1">{{ $t('newAlertIntroText') }}</div>
              </div>

              <div v-if="noUsersWithDevices" class="text-center c-banner warn">
                <p>{{ $t('newAlertNoDevices') }}</p>
                <app-links />
              </div>

              <v-form ref="form" v-model="formValid">
                <v-row class="py-4">
                  <v-col cols="12" lg="3" class="d-flex flex-column">
                    <step :number="1" :title="$t('message')" class="flex-grow-1">
                      <v-text-field
                        v-model="form.title"
                        :rules="titleRules"
                        :label="$t('title')"
                        :hint="$t('newAlertTitleHelperText')"
                        autocomplete="off"
                        :counter="formLengths.title"
                      />
                      <v-textarea
                        v-model="form.message"
                        :rules="messageRules"
                        :label="$t('message')"
                        :hint="$t('newAlertMessageHelperText')"
                        rows="2"
                        auto-grow
                        autocomplete="off"
                        :counter="formLengths.message"
                      />
                      <div class="pt-6">
                        <div>
                          <v-btn
                            v-if="editedAttachments.length <= 0"
                            class="pl-0"
                            plain
                            color="gray800"
                            @click="addAttachmentToAdditions"
                          >
                            {{ $t('addAttachments') }}
                            <v-icon right dark> fa-paperclip </v-icon>
                          </v-btn>
                          <span v-if="editedAttachments.length > 0">{{ $t('attachments') }}</span>
                        </div>
                        <attachment-grid
                          v-if="editedAttachments.length > 0"
                          :attachments="editedAttachments"
                          editable
                          preview
                          @add="addAttachmentToAdditions"
                          @remove="addAttachmentToRemovals"
                        />
                      </div>
                    </step>
                  </v-col>
                  <v-col cols="12" lg="3" class="d-flex flex-column">
                    <step :number="2" :title="$t('recipients')" class="flex-grow-1">
                      <v-switch
                        v-if="!singleUsersSelected"
                        v-model="form.target.company"
                        :label="$t('companyWide')"
                        :hint="$t('newAlertCompanyWideHelperText')"
                        persistent-hint
                        class="mb-4"
                        @change="($event) => companyWideChanges($event)"
                      />
                      <team-selector
                        v-if="!singleUsersSelected"
                        v-model="form.target.teams"
                        :teams="teams"
                        :disabled="form.target.company"
                        :label="$t('teams')"
                        :rules="teamRules"
                        item-value="value"
                        return-object
                        prepend-icon="fa-users"
                        :hint="$t('newAlertTeamsHelperText')"
                        persistent-hint
                        clearable
                        @teams-loaded="($teams) => updateTeams($teams)"
                        @input="($selectedTeams) => selectedTeamsChanges($selectedTeams)"
                      />

                      <div :class="{ 'mt-6 mb-2': !singleUsersSelected }">
                        <v-btn
                          v-if="!singleUsersSelected"
                          color="primary"
                          text
                          block
                          class="mr-2"
                          @click="selectUsersDialog = true"
                          ><v-icon left class="pa-1">fa-user</v-icon>
                          {{ $t('selectSingleUsers') }}
                        </v-btn>
                        <v-divider v-if="!singleUsersSelected" />
                        <v-btn
                          v-if="singleUsersSelected"
                          color="primary"
                          block
                          class="mr-2"
                          @click="selectUsersDialog = true"
                          ><v-icon left class="pa-1">fa-user</v-icon>
                          {{ $t('editSingleUsers') }}
                        </v-btn>
                        <div v-if="singleUsersSelected" class="ma-2">
                          {{ $t('targetResetChangedUsers') }}
                        </div>
                      </div>

                      <div v-if="singleUsersSelected" class="my-2">
                        <v-chip
                          v-for="user in form.target.users.slice(0, 3)"
                          :key="user.id"
                          pill
                          outlined
                          small
                          class="ma-1"
                          >{{ user.name }}
                        </v-chip>
                        <v-chip
                          v-if="form.target.users.length > 3"
                          class="ma-1"
                          small
                          pill
                          @click="selectUsersDialog = true"
                          >+{{ form.target.users.length - 3 }}
                        </v-chip>
                      </div>
                      <div class="d-flex justify-center my-2">
                        <v-btn
                          v-if="singleUsersSelected"
                          color="primary"
                          text
                          block
                          class="pl-0 mt-2"
                          @click="clearTargetUsers"
                          ><v-icon left class="pa-1">fa-undo</v-icon>
                          {{ $t('clearRecipients') }}
                        </v-btn>
                      </div>
                      <select-users-dialog
                        v-model="selectUsersDialog"
                        :users="users"
                        :target="form.target"
                        @done="($users) => selectSingleUsersDone($users)"
                      />

                      <recipient-reach
                        :has-fallback-sms="userReach.hasFallbackSms"
                        :users-with-devices-count="userReach.usersWithDevicesCount"
                        :users-without-devices-count="userReach.usersWithoutDevicesCount"
                        class="mt-6"
                      />
                    </step>
                  </v-col>
                  <v-col cols="12" lg="3" class="d-flex flex-column">
                    <step
                      :number="3"
                      :title="`${$t('location')} (${$t('optional')})`"
                      class="flex-grow-1"
                    >
                      <div>{{ $t('locationsIntroText') }}</div>
                      <div class="mt-4">
                        <div v-if="form.target.location">
                          {{
                            $t('predefinedLocationSelected', null, {
                              predefLocation: form.target.location.name,
                            })
                          }}
                        </div>
                        <div v-else-if="form.target.adHocLocation">
                          {{ $t('customLocationSelected') }}
                        </div>
                      </div>
                      <div class="d-flex justify-center my-6">
                        <v-btn color="primary" @click="openMap"
                          ><v-icon left class="pa-1">fa-map-marker</v-icon
                          >{{ $t('selectInMap') }}</v-btn
                        >
                      </div>
                    </step>
                  </v-col>
                  <v-col cols="12" lg="3" class="d-flex flex-column">
                    <step :number="4" :title="$t('severity')" class="flex-grow-1">
                      <div>
                        <v-btn-toggle
                          v-model="form.severity"
                          borderless
                          mandatory
                          class="severity-selector"
                        >
                          <v-btn value="urgentAlert" color="yellowPrimary" style="height: 96px">
                            <v-img src="@/assets/images/urgent-alert.svg" max-width="100px" />
                          </v-btn>
                          <v-btn value="alarm" color="redPrimary" style="height: 96px">
                            <v-img src="@/assets/images/alarm.svg" max-width="100px" />
                          </v-btn>
                        </v-btn-toggle>
                        <div>
                          <v-icon color="primary" class="pa-1">fa-check-circle</v-icon>
                          {{ $t(form.severity) }}
                        </div>
                        <div>{{ $t(`severityInformation_${form.severity}`) }}</div>
                      </div>
                      <v-switch
                        v-model="form.alertSound"
                        :disabled="!alertSoundConfigurable"
                        :label="$t('alertSound')"
                        :messages="$t('alertSoundHelperText')"
                        class="mt-4"
                      />
                      <p v-if="!alertSoundConfigurable" class="mt-2">
                        {{ $t('alertSoundNotConfigurable') }}
                      </p>
                    </step>
                  </v-col>
                </v-row>
              </v-form>

              <div class="d-flex justify-end">
                <v-btn text class="mr-1" @click="cancel">{{ $t('cancel') }}</v-btn>
                <v-btn
                  :disabled="
                    !formValid ||
                    (!userReach.hasFallbackSms && userReach.usersWithDevicesCount === 0)
                  "
                  color="primary"
                  @click="goToPreview"
                  >{{ $t('next') }}</v-btn
                >
              </div>
            </v-tab-item>
            <v-tab-item eager>
              <div class="text-lg-center mb-4">
                <div class="text-h4">{{ $t('alertReady') }}</div>
                <div v-if="isEditor" class="text-subtitle-1">{{ $t('reviewBeforeSaving') }}</div>
                <div v-else class="text-subtitle-1">{{ $t('reviewBeforeSending') }}</div>
              </div>

              <div class="d-lg-flex">
                <v-row class="text-center">
                  <v-col cols="12" lg="3">
                    <notification-preview
                      :severity="form.severity"
                      :title="form.title"
                      :message="form.message"
                    />
                  </v-col>
                  <v-col cols="12" lg="3" class="text-start">
                    <v-card>
                      <v-card-title>
                        {{ form.title }}
                      </v-card-title>
                      <v-card-subtitle style="white-space: pre"
                        >{{ form.message }}
                      </v-card-subtitle>
                      <hr />
                      <v-card-text>
                        <p v-if="form.target.company" class="text-body-1">
                          {{ $t('newAlertRecipientsCompany') }}
                        </p>
                        <div v-else-if="form.target.teams.length > 0" class="text-body-1">
                          <p class="mb-1">{{ $t('newAlertRecipientsTeams') }}</p>
                          <ul>
                            <li v-for="(team, index) in form.target.teams" :key="index">
                              {{ team.name }}
                            </li>
                          </ul>
                        </div>
                        <p v-else class="text-body-1">{{ $t('newAlertRecipientsUsers') }}</p>

                        <div v-if="userReach.hasFallbackSms">
                          <p class="text-body-1">
                            {{
                              userReach.usersCount === 1
                                ? $t('alertWillBeSentToSingle')
                                : $t('alertWillBeSentToPlural', null, {
                                    recipientCount: userReach.usersCount,
                                  })
                            }}
                          </p>
                          <template v-if="userReach.usersWithoutDevicesCount > 0">
                            <v-alert
                              v-if="userReach.usersWithoutDevicesPercentage > 64"
                              dense
                              border="left"
                              colored-border
                              color="orange"
                              elevation="0"
                              class="text-body-2 text--secondary"
                            >
                              {{
                                userReach.usersWithoutDevicesCount === 1
                                  ? $t('alertRecipientWarningSingle')
                                  : $t('alertRecipientWarningPlural', null, {
                                      recipientCount: userReach.usersWithoutDevicesCount,
                                    })
                              }}
                            </v-alert>
                            <p v-else class="text-body-2 text--secondary">
                              {{
                                userReach.usersWithoutDevicesCount === 1
                                  ? $t('alertRecipientWarningSingle')
                                  : $t('alertRecipientWarningPlural', null, {
                                      recipientCount: userReach.usersWithoutDevicesCount,
                                    })
                              }}
                            </p>
                          </template>
                        </div>

                        <div v-else>
                          <p class="text-body-1">
                            {{
                              userReach.usersWithDevicesCount === 1
                                ? $t('alertWillBeSentToSingle')
                                : $t('alertWillBeSentToPlural', null, {
                                    recipientCount: userReach.usersWithDevicesCount,
                                  })
                            }}
                          </p>
                          <v-alert
                            v-if="userReach.usersWithoutDevicesCount > 0"
                            dense
                            border="left"
                            colored-border
                            :color="userReach.usersWithoutDevicesPercentage > 29 ? 'red' : 'orange'"
                            elevation="0"
                            class="text-body-2 text--secondary"
                          >
                            {{
                              userReach.usersWithoutDevicesCount === 1
                                ? $t('alertRecipientErrorSingle')
                                : $t('alertRecipientErrorPlural', null, {
                                    recipientCount: userReach.usersWithoutDevicesCount,
                                  })
                            }}
                          </v-alert>
                        </div>

                        <div v-if="uploadingAttachments" class="mt-6">
                          <v-progress-linear indeterminate color="primary" />
                        </div>
                        <div v-if="editedAttachments.length > 0" class="mt-6">
                          Attatchments: {{ editedAttachments.length }}
                          <attachment-grid
                            :attachments="editedAttachments"
                            preview
                            @remove="addAttachmentToRemovals"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-card v-if="showPreviewMap">
                      <v-card-text>
                        <p class="text-start mb-4">
                          <template v-if="predefinedLocationSelected">
                            {{
                              $t('predefinedLocationSelected', null, {
                                predefLocation: form.target.location.name,
                              })
                            }}
                          </template>
                          <template v-else>
                            {{ $t('customLocationSelected') }}
                          </template>
                        </p>
                      </v-card-text>
                      <v-card-actions ref="previewMap" class="preview-map-wrapper" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <div class="d-flex justify-end">
                <v-btn text class="mr-1" @click="goToCompose">{{ $t('back') }}</v-btn>
                <v-btn v-if="isEditor" color="primary" @click="save">{{ $t('save') }}</v-btn>
                <v-btn v-else color="primary" @click="send">{{ $t('send') }}</v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import AppLinks from '@/components/AppLinks';
import Step from '@/views/newAlert/Step';
import RecipientReach from '@/views/newAlert/RecipientReach';
import TeamSelector from '@/components/TeamSelector';
import api from '@/util/api';
import map from '@/map/map';
import NotificationPreview from '@/components/NotificationPreview';
import confirmDialog from '@/components/dialog/confirmDialog';
import router from '@/router';
import errorMessage from '@/util/errorMessage';
import AttachmentGrid from '@/components/AttachmentGrid';
import fileInput from '@/util/fileInput';
import getFileExtension from '@/util/getFileExtension';
import { BlockBlobClient } from '@azure/storage-blob';
import SelectUsersDialog from '@/components/dialog/SelectUsersDialog';

const formLengths = {
  title: 50,
  message: 1500,
};

const userReachDefaults = {
  usersCount: 0,
  usersWithDevicesCount: 0,
  usersWithoutDevicesCount: 0,
  usersWithoutDevicesPercentage: 0,
  hasFallbackSms: true,
};

export default {
  name: 'NewAlert',

  components: {
    AppLinks,
    Step,
    RecipientReach,
    TeamSelector,
    NotificationPreview,
    AttachmentGrid,
    SelectUsersDialog,
  },

  beforeRouteLeave(to, from, next) {
    // TODO: check if we need to handle for scenarios as well
    if (this.alertId > 0) {
      if (to.path === '/alerts/' + this.alertId) {
        // navigating to details indicates that alert was sent successfully
        next();
      } else {
        api.deleteDraft(this.alertId).then(() => {
          next();
        });
      }
    } else {
      next();
    }
  },

  props: {
    data: {
      type: Object,
      required: false,
      default: null,
    },
    severity: {
      type: String,
      required: false,
      default: 'urgentAlert',
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    scenario: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: (self) => ({
    tab: 0,
    selectUsersDialog: null,
    showForm: true,
    showMap: false,
    showMapPanel: false,
    showMapTools: false,

    map: null,
    previewMap: null,

    userReach: { ...userReachDefaults },

    noUsersWithDevices: false,
    users: [],
    teams: [],
    locations: [],
    uniqueTeamMembersCount: 0,
    alertSoundSetting: 'user',
    alertId: 0,

    currentLocation: null,
    currentAdHocLocation: null,

    formValid: false,

    form: {
      title: '',
      message: '',
      severity: 'urgentAlert',
      alertSound: true,
      target: {
        company: false,
        teams: [],
        users: [],
        location: null,
        adHocLocation: '',
      },
      attachments: [],
    },
    formLengths,
    uploadingAttachments: false,
    filesToAdd: [],
    filesToRemove: [],
    titleRules: [
      (title) =>
        title?.length >= 3 ||
        self.$t('mustBeMinimum', null, {
          value: self.$t('title'),
          characters: 3,
        }),
      (title) =>
        title?.length <= formLengths.title ||
        self.$t('mustBeMaximum', null, {
          value: self.$t('title'),
          characters: formLengths.title,
        }),
    ],

    messageRules: [
      (message) =>
        message?.length >= 3 ||
        self.$t('mustBeMinimum', null, {
          value: self.$t('message'),
          characters: 3,
        }),
      (message) =>
        message?.length <= formLengths.message ||
        self.$t('mustBeMaximum', null, {
          value: self.$t('message'),
          characters: formLengths.message,
        }),
    ],
  }),

  computed: {
    singleUsersSelected() {
      return this.form.target.users.length > 0;
    },
    predefinedLocationSelected() {
      return this.form.target.location;
    },

    showPreviewMap() {
      return this.form.target.location || this.form.target.adHocLocation;
    },

    teamRules() {
      if (this.form.target.company) {
        return [];
      } else if (this.form.target.users.length > 0) {
        return [];
      } else {
        return [(teams) => teams.length > 0 || this.$t('invalidRecipients')];
      }
    },

    isEditor() {
      return !!this.data;
    },

    hasData() {
      return this.data?.title?.length > 0;
    },

    alertSoundConfigurable() {
      return this.alertSoundSetting !== 'off' && this.alertSoundSetting !== 'on';
    },
    editedAttachments() {
      return [
        ...this.filesToAdd,
        ...this.form.attachments.filter((a) => this.filesToRemove.indexOf(a) < 0),
      ];
    },
  },

  watch: {
    currentLocation() {
      this.map.getLocations().forEach((l) => {
        l.setChecked(l.id === this.currentLocation?.id);
      });
    },

    data() {
      this.reset();
    },

    severity() {
      this.reset();
    },
  },

  async mounted() {
    await this.loadAllData();
    this.reset();
    this.initMap();
  },
  methods: {
    clearTargetUsers() {
      this.form.target.users = [];
      this.getReach();
    },
    companyWideChanges(companyWide) {
      if (companyWide) {
        this.form.target.teams = [];
        this.form.target.users = [];
      }
      this.getReach();
    },
    selectedTeamsChanges() {
      // reset selected users when selecting teams instead
      if (this.form.target.users.length > 0) {
        this.form.target.users = [];
      }
      this.getReach();
    },
    selectSingleUsersDone(newTarget) {
      // Order users alphabetically
      this.form.target = newTarget;
      this.form.target.users.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.getReach();
    },

    async addAttachmentToAdditions() {
      const files = await fileInput();

      if (!files?.length) {
        return;
      }

      // FileList does not support forEach, so we NEED to use a for-loop
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fr = new FileReader();

        fr.addEventListener(
          'load',
          () => {
            const presignedUrl = fr.result;
            this.filesToAdd.push({
              name: file.name,
              presignedUrl,
              file: file,
              size: file.size,
            });
          },
          false,
        );

        fr.readAsDataURL(file);
      }
    },
    addAttachmentToRemovals(attachment) {
      if (attachment.file) {
        this.filesToAdd = this.filesToAdd.filter((a) => a !== attachment);
      } else {
        this.filesToRemove.push(attachment);
      }
    },
    reset() {
      this.tab = 0;
      this.$refs.form.resetValidation();
      this.form.title = this.data?.title ?? '';
      this.form.message = this.data?.body ?? '';
      this.form.severity = this.severity ?? 'urgentAlert';
      this.form.alertSound = !this.data?.silent && this.alertSoundSetting !== 'off';
      this.form.target.company = this.data?.target?.company ?? false;
      this.form.target.teams = this.teams?.filter((team) =>
        this.data?.target?.teams.find((teamId) => team.id === teamId),
      );
      this.form.target.users = this.users?.filter((user) =>
        this.data?.target?.users.find((userId) => user.id === userId),
      );
      this.form.target.location =
        this.locations?.filter((location) =>
          this.data?.target?.locations.find((locationId) => location.id === locationId),
        )[0] ?? null;
      this.form.target.adHocLocation = this.data?.target?.adHocLocation ?? null;
      this.form.attachments = this.scenario?.attachments ?? [];

      this.getReach(); // Workaround for 'edit scenario', causes unneeded api calls
    },

    initMap() {
      this.map = map();
      this.$refs.map.innerHTML = '';
      this.$refs.map.append(this.map.element);
    },

    loadAllData() {
      return Promise.all([
        this.loadUsers(),
        this.loadLocations(),
        this.loadCustomerAlertSoundMode(),
      ]);
    },

    async loadUsers() {
      return api.getUsers().then((res) => {
        // order users by name
        res.users.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.users = res.users;
        let deviceCount = 0;
        this.users.forEach((user) => {
          deviceCount += user.deviceCount;
        });
        this.noUsersWithDevices = deviceCount < 1;
      });
    },
    updateTeams(teams) {
      this.teams = teams;
      if (this.data?.target?.teams?.length > 0 && this.form.target.teams.length < 1) {
        this.form.target.teams = this.teams?.filter((team) =>
          this.data?.target?.teams.find((teamId) => team.id === teamId),
        );
      }
      this.getReach(); // Workaround for 'edit scenario' with a preselected team
    },
    loadLocations() {
      return api.getLocations().then((res) => {
        this.locations = res.locations;
      });
    },

    getReach() {
      const target = this.form.target;
      if (target.company === false && target.teams.length === 0 && target.users.length === 0) {
        this.userReach = { ...userReachDefaults };
      } else {
        const apiTarget = {
          company: this.form.target.company,
          teams: this.form.target.teams.map((team) => team.id),
          users: this.form.target.users.map((user) => user.id),
        };

        return api
          .getTargetReach(apiTarget)
          .then((res) => {
            this.userReach = res;
          })
          .catch(() => {
            this.userReach = { ...userReachDefaults };
          });
      }
    },

    async loadCustomerAlertSoundMode() {
      const settings = await api.getCustomerSettings();

      if (!settings) return;

      const alertSoundSetting = settings.alertSound;

      if (alertSoundSetting) {
        this.alertSoundSetting = settings.alertSound;
      }
    },

    loadUniqueTeamMembersCount() {
      if (!this.form.target.company && this.form.target.teams.length > 0) {
        const teamIds = this.form.target.teams.map((team) => team.id);

        if (teamIds.length > 0) {
          api.getUniqueMemberCount(this.form.target.teams.map((team) => team.id)).then((res) => {
            this.uniqueTeamMembersCount = res.memberCount;
          });
        }
      }
    },

    saveDraft() {
      const locations = [];
      if (this.form.target.location?.id > 0) {
        locations.push(this.form.target.location.id);
      }
      const form = {
        ...this.form,
        message: this.form.message,
        silent: this.form.alertSound === false,
        target: {
          ...this.form.target,
          teams: this.form.target.teams.map((t) => t.id),
          users: this.form.target.users.map((u) => u.id),
          locations,
          adHocLocation: this.form.target.adHocLocation,
        },
      };
      api.saveDraft(form);
    },

    goToCompose() {
      this.tab = 0;
    },

    async goToPreview() {
      this.tab = 1;
      this.loadUniqueTeamMembersCount();
      if (this.showPreviewMap) {
        this.previewMap = map({
          interactive: false,
        });
        this.$refs.previewMap.innerHTML = '';
        this.$refs.previewMap.append(this.previewMap.element);
        setTimeout(() => {
          this.previewMap.redrawTiles();

          const target = this.form.target;
          if (target.location) {
            this.previewMap.addLocation({
              geofence: target.location.geofence,
            });
          } else if (target.adHocLocation) {
            this.previewMap.setCurrentDrawingFromString(target.adHocLocation);
          }
        }, 500);
      }

      if (!this.isEditor) {
        let alertForm;
        let alertType = 'alert';
        if (this.form.severity === 'alarm') {
          alertType = 'alarm';
        }
        if (this.alertId <= 0) {
          const locations = [];
          if (this.form.target.location?.id > 0) {
            locations.push(this.form.target.location.id);
          }

          alertForm = {
            ...this.form,
            status: 'draft',
            message: this.form.message,
            silent: this.form.alertSound === false,
            severity: alertType,
            target: {
              ...this.form.target,
              teams: this.form.target.teams.map((t) => t.id),
              users: this.form.target.users.map((t) => t.id),
              locations,
              adHocLocation: this.form.target.adHocLocation,
            },
          };
          const res = await api.saveDraft(alertForm);
          this.alertId = res.alertId;
        }

        if (this.alertId > 0) {
          const locations = [];
          if (this.form.target.location?.id > 0) {
            locations.push(this.form.target.location.id);
          }
          alertForm = {
            ...this.form,
            status: 'draft',
            message: this.form.message,
            silent: this.form.alertSound === false,
            severity: alertType,
            target: {
              ...this.form.target,
              teams: this.form.target.teams.map((t) => t.id),
              users: this.form.target.users.map((t) => t.id),
              locations,
              adHocLocation: this.form.target.adHocLocation,
            },
            attachments: this.editedAttachments,
          };
          // Check if file has alerady been uploaded (by moving back and forth in wizard)
          await this.saveAttachments(
            this.alertId,
            'getAlertAttachmentUploadlink',
            'getAttachmentDeleteUrl',
          );

          alertForm.attachments = this.editedAttachments;
          await api.updateAlert(this.alertId, alertForm);
        }
      } else {
        // Handle editing scenario with attachments
        await this.saveAttachments(
          this.scenario.id,
          'getScenarioAttachmentUploadUrl',
          'getScenarioAttachmentDeleteUrl',
        );

        let scenario = await api.getScenario(this.scenario.id);
        scenario.alertData = {};

        let alertType = 'alert';
        if (this.form.severity === 'alarm') {
          alertType = 'alarm';
        }
        const locations = [];
        if (this.form.target.location?.id > 0) {
          locations.push(this.form.target.location.id);
        }
        const form = {
          title: this.form.title,
          body: this.form.message,
          silent: this.form.alertSound === false,
          severity: alertType,
          target: {
            company: this.form.target.company,
            teams: this.form.target.teams.map((t) => t.id),
            users: this.form.target.users.map((t) => t.id),
            locations,
            adHocLocation: this.form.target.adHocLocation,
          },
        };
        scenario.alertData = form;
        scenario.attachments = this.editedAttachments;

        await api.updateScenario(scenario);
      }
    },

    async saveAttachments(id, getAttachmentUploadLink, getAttachmentDeleteUrl) {
      if (this.filesToAdd.length <= 0) {
        return;
      }

      this.uploadingAttachments = true;
      const blobTasks = [];
      this.filesToAdd?.forEach((attachment) => {
        if (!attachment.isUploaded) {
          blobTasks.push(async () => {
            const extension = getFileExtension(attachment.name);
            const res = await api[getAttachmentUploadLink](id, extension);
            attachment.url = res.key;
            const blobClient = new BlockBlobClient(res.presignedUrl);
            await blobClient.uploadData(attachment.file);
            attachment.isUploaded = true;
          });
        }
      });

      this.filesToRemove?.forEach((attachment) => {
        blobTasks.push(async () => {
          const res = await api[getAttachmentDeleteUrl](id, attachment.url);
          const blobClient = new BlockBlobClient(res.presignedUrl);
          await blobClient.delete();
        });
      });

      await Promise.all(blobTasks.map((task) => task()));
      this.uploadingAttachments = false;
    },

    cancelEditLocation() {
      this.closeMap();
    },

    useCurrentLocationOrAdHocLocation() {
      const target = this.form.target;

      target.location = null;
      target.adHocLocation = null;

      if (this.currentAdHocLocation) {
        target.adHocLocation = this.currentAdHocLocation;
      } else if (this.currentLocation) {
        target.location = this.currentLocation;
      }

      this.currentLocation = null;
      this.currentAdHocLocation = null;
      this.closeMap();
    },

    openMapTools() {
      this.showMapPanel = false;
      this.showMapTools = true;
      this.map.startEditDrawing();
    },

    closeMapTools() {
      this.showMapPanel = true;
      this.showMapTools = false;
      this.currentAdHocLocation = this.map.getCurrentDrawingAsString();
    },

    closeMap() {
      this.showForm = true;
      this.showMap = false;
      this.showMapPanel = false;
      this.showMapTools = false;
      this.map.clearDrawing();
      this.map.clearLocations();
    },

    openMap() {
      this.showForm = false;
      this.showMap = true;
      this.showMapPanel = true;
      this.showMapTools = false;

      const target = this.form.target;
      if (target.adHocLocation) {
        this.currentAdHocLocation = target.adHocLocation;
        this.map.setCurrentDrawingFromString(target.adHocLocation);
      } else if (target.location) {
        this.currentLocation = target.location;
      }

      this.map.clearLocations();
      this.locations.forEach((location) => {
        this.map.addLocation(
          {
            geofence: location.geofence,
            id: location.id,
            marker: {
              name: location.name,
              onClick: () => {
                if (!this.currentAdHocLocation && this.showMapPanel) {
                  this.currentLocation = location;
                }
              },
            },
          },
          false,
        );
      });
      this.map.fitLocations();
    },

    startDrawingPolygon() {
      this.map.startDrawing('polygon');
    },

    startDrawingCircle() {
      this.map.startDrawing('circle');
    },

    cancelEditCurrentAdHocLocation() {
      this.map.stopEditDrawing();
      this.map.clearDrawing();
      if (this.currentAdHocLocation) {
        this.map.setCurrentDrawingFromString(this.currentAdHocLocation);
      }
      this.closeMapTools();
    },

    clearCurrentAdHocLocation() {
      this.map.clearDrawing();
    },

    useCurrentAdHocLocation() {
      this.closeMapTools();
      this.map.stopEditDrawing();
    },

    cancel() {
      if (!this.isEditor) {
        router.push('/');
      }
      this.$emit('cancel');
      this.reset();
    },

    save() {
      const locations = [];
      if (this.form.target.location?.id > 0) {
        locations.push(this.form.target.location.id);
      }
      const form = {
        title: this.form.title,
        body: this.form.message,
        silent: this.form.alertSound === false,
        severity: this.form.severity,
        target: {
          company: this.form.target.company,
          teams: this.form.target.teams.map((t) => t.id),
          users: this.form.target.users.map((t) => t.id),
          locations,
          adHocLocation: this.form.target.adHocLocation,
        },
        attachments: this.editedAttachments,
      };
      this.$emit('save', this.form.severity, form);
      this.reset();
    },

    send() {
      const locations = [];
      if (this.form.target.location?.id > 0) {
        locations.push(this.form.target.location.id);
      }
      let alertType = 'alert';
      if (this.form.severity === 'alarm') {
        alertType = 'alarm';
      }
      const form = {
        ...this.form,
        message: this.form.message,
        silent: this.form.alertSound === false,
        severity: alertType,
        target: {
          ...this.form.target,
          teams: this.form.target.teams.map((t) => t.id),
          users: this.form.target.users.map((t) => t.id),
          locations,
          adHocLocation: this.form.target.adHocLocation,
        },
        attachments: this.editedAttachments,
      };

      confirmDialog(
        this.$t('sendAlert'),
        this.$t('sendAlertDialogText'),
        this.$t('cancel'),
        this.$t('send'),
      )
        .open()
        .then((confirm) => {
          if (confirm) {
            api
              .updateAlert(this.alertId, form)
              .then(() => {
                router.push('/alerts/' + this.alertId);
              })
              .catch((res) =>
                errorMessage(this.$t('sendAlertError'), this.$t('sendAlertErrorMsg'), res),
              );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/mixins';

$pageHeight: calc(100vh - 56px);
$pageHeightLarge: calc(100vh - 64px);
$pageHeightFullscreen: 100vh;
$zIndexAboveMap: 999;

@mixin aboveMap {
  z-index: $zIndexAboveMap;
}

.map-container {
  @include material-transition-slow(filter);
  filter: grayscale(0.8) brightness(0.5);

  &.show {
    filter: grayscale(0) brightness(1);
  }

  .map-panel,
  .map-draw-tools {
    @include material-transition-slow(left);
    @include aboveMap;
    max-width: 300px;
    position: absolute;
    top: 12px;
    left: -100%;

    &.show {
      left: 12px;
    }
  }
  &.fullscreen ::v-deep .c-map {
    height: $pageHeightFullscreen;
  }
  & ::v-deep .c-map {
    height: $pageHeight;
  }
}

.form-container {
  @include material-transition-slow;
  position: absolute;
  overflow-y: auto;
  height: $pageHeight;
  top: -100%;
  bottom: 100%;
  left: 0;
  right: 0;
  opacity: 0;

  &.fullscreen {
    height: $pageHeightFullscreen;
  }
  &.show {
    top: 0;
    bottom: 0;
    opacity: 1;
  }

  .form-card {
    width: 100%;
    max-width: 1900px;
    background-color: #f1f1f1;
    color: rgba(0, 0, 0, 0.87) !important;

    .severity-selector {
      width: 100%;
      max-width: 512px;

      @keyframes scaleUp {
        from {
          transform: scale(0.7);
        }
        to {
          transform: scale(1);
        }
      }
      @keyframes scaleDown {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(0.7);
        }
      }
      button {
        width: 50%;

        .v-image {
          animation-duration: 0.5s;
          animation-name: scaleDown;
          animation-fill-mode: forwards;
        }

        &.v-item--active {
          .v-image {
            animation-name: scaleUp;
          }
        }
      }
    }
    .text-subtitle-1 {
      color: rgba(0, 0, 0, 0.87);
    }
    .text-h4 {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .preview-map-wrapper {
    width: 100%;
    height: 100%;

    & ::v-deep .c-map {
      width: 100%;
      height: 300px;
      z-index: 0;
    }
  }
}
// TODO: Look into replacing css with max-rows prop on v-textarea in vuetify3
.v-textarea ::v-deep textarea {
  max-height: 300px;
  overflow: auto;
}
@media all and (min-width: 960px) {
  .map-container ::v-deep .c-map,
  .form-container {
    height: $pageHeightLarge;
  }
  .map-container.fullscreen ::v-deep .c-map,
  .form-container.fullscreen {
    height: $pageHeightFullscreen;
  }
}
</style>
