<template>
  <div class="preview">
    <div class="phone">
      <div class="phone-top">
        <div class="led-light" :class="{ alarm }" />
      </div>
      <div class="screen">
        <div v-if="!alarm" class="notification">
          <div class="header">
            <v-img contain class="icon" src="@/assets/images/logo-teamalert-white.svg" />
            <span class="app-name">TeamAlert</span>
            <span class="separator">•</span>
            <span class="timestamp">now</span>
          </div>
          <h3 class="notification-title">{{ title.substring(0, 27) }}</h3>
          <p class="notification-message">{{ message.substring(0, 110) }}</p>
        </div>

        <div v-else class="fullscreen-notification">
          <v-img contain class="icon" src="@/assets/images/alarm-white.svg" />
          <h3 class="notification-title">{{ title.substring(0, 20) }}</h3>
          <p class="notification-message">{{ message.substring(0, 50) }}</p>
          <div class="screen-unlock">
            <div class="screen-unlock-inner">
              <v-icon class="arrow-icon">fa-arrow-right</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="phone-bottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationPreview',

  props: {
    severity: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },
  },

  computed: {
    alarm() {
      return this.severity === 'alarm';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/scss/settings/mixins';
@import '@/style/scss/settings/colors';

$phoneWidth: 200px;
$phoneHeight: $phoneWidth * 2;
$phoneTopHeight: $phoneHeight * 0.075;
$phoneBottomHeight: $phoneHeight / 10;
$phoneScreenHeight: $phoneHeight - $phoneTopHeight - $phoneBottomHeight;
$notificationY: $phoneHeight / 20;

@keyframes flash {
  0%,
  45% {
    opacity: 0;
  }

  55%,
  100% {
    opacity: 1;
  }
}

.preview {
  .phone {
    width: $phoneWidth;
    height: $phoneHeight;
    margin: auto;
    background-color: $colorGray900;
    border-radius: 5px;
    overflow: hidden;

    .phone-top {
      height: $phoneTopHeight;
      position: relative;

      .led-light {
        $size: 6px;
        @include circle($size);
        position: absolute;
        left: 12.5px;
        top: 12.5px;
        background-color: $colorYellowLight;
        animation-duration: 1s;
        animation-name: flash;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        &.alarm {
          background-color: $colorRedLight;
          animation-duration: 0.3s;
        }
      }
    }

    .screen {
      height: $phoneScreenHeight;
      margin: 0 5px;
      background-color: $colorBlueDark;
      position: relative;

      .notification {
        width: 100%;
        position: absolute;
        top: 20px;
        padding: 5px 10px;
        background-color: white;
        text-align: start;
        overflow: hidden;

        .header {
          font-size: 5pt;
          line-height: 5pt;
          margin-bottom: 5px;

          > * {
            display: inline-block;
            vertical-align: middle;
            margin-right: 3px;
          }

          .icon {
            @include circle(10px);
            background-color: $colorYellowPrimary;
          }

          .app-name {
            color: $colorBluePrimary;
          }

          .separator,
          .timestamp {
            color: $colorGray400;
          }
        }

        .notification-title {
          $size: 7pt;
          font-size: $size;
          line-height: $size;
          min-height: $size;
          margin: 0;
        }

        .notification-message {
          $size: 5pt;
          font-size: $size;
          line-height: $size;
          min-height: $size;
          margin: 5px 0 0 0;
        }
      }

      .fullscreen-notification {
        width: 100%;
        height: 100%;
        background-color: $colorRedPrimary;
        padding: 16px;
        overflow: hidden;

        .icon {
          @include square(100px);
          margin: auto;
        }

        .notification-title {
          $size: 20pt;
          font-size: $size;
          line-height: $size;
          min-height: $size;
          color: white;
          text-align: center;
          font-weight: 700;
          margin: 0 0 10px 0;
        }

        .notification-message {
          $size: 14pt;
          font-size: $size;
          line-height: $size;
          min-height: $size;
          color: white;
          text-align: start;
          margin: 0;
        }

        .screen-unlock {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: $colorGray900transparent50;
          padding: 4px;

          .screen-unlock-inner {
            position: relative;
            width: 100%;
            height: 36px;
            border-radius: 36px;
            background-color: $colorGray900transparent50;

            .arrow-icon {
              position: absolute;
              left: 3px;
              top: 3px;
              color: white;
              background-color: $colorGray900transparent50;
              border-radius: 30px;
              width: 30px;
              height: 30px;
              padding: 5px;
            }
          }
        }
      }
    }

    .phone-bottom {
      height: $phoneBottomHeight;
    }
  }
}
</style>
