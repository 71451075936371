<template>
  <v-card class="step mt-2">
    <span class="step-number">{{ number }}</span>
    <v-card-title class="step-title pt-6">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="step-content">
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'Step',

  props: {
    number: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$size: 40px;
$halfSize: $size / 2;

.step {
  span.step-number {
    background-color: white;
    position: absolute;
    top: -$halfSize;
    left: calc(50% - #{$halfSize});
    width: $size;
    height: $halfSize;
    line-height: $size;
    text-align: center;
    border-top-left-radius: $size !important; /* 100px of height + 10px of border */
    border-top-right-radius: $size !important; /* 100px of height + 10px of border */
    border: 1px solid #dbdbdb;
    border-bottom: 0;
    color: #303b3f;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
